export default {
  DFID: {}, // computed
  DGID: {}, // computed
  datagroups: (_this) => [{
    // services
    id: 'services',
    datafields: [{
      // services > online
      id: 'online',
      icon: 'dot',
      renderInList: false,
      value: {
        option: 0,
        options: [{
          label: _this.$t('OFF')
        }, {
          label: _this.$t('ON')
        }]
      },
      indicates: {
        indicator: () => {
          return _this.product.data.channel.online
        },
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            text: [_this.channel.presense_count, _this.$t('ONLINE.LABEL')].join(' ')
          }
        }
      }
    }, {
      // services > orders
      id: 'orders',
      icon: 'cart.fill',
      renderInList: false,
      value: {
        option: 0,
        options: [{
          label: _this.$t('ORDERS.ACCEPTING')
        }, {
          label: _this.$t('ORDERS.NOT_ACCEPTING')
        }]
      }
    }, {
      // services > wifi
      id: 'wifi',
      icon: 'station.waves',
      notifyLabel: true,
      value: {
        option: 1,
        options: [{
          label: _this.$t('OFF')
        }, {
          label: _this.$t('ON')
        }]
      }
    // }, {
    //   // services > kitchen_training
    //   types: ['cafe', 'food', 'restaurant'],
    //   id: 'kitchen_training',
    //   icon: 'station.wait',
    //   valueType: Boolean
    // }, {
    //   // services > barista_training
    //   types: ['cafe', 'food', 'restaurant'],
    //   id: 'barista_training',
    //   icon: 'station.wait',
    //   valueType: Boolean
    // }, {
    //   // services > kitchen_staff
    //   types: ['cafe', 'food', 'restaurant'],
    //   id: 'kitchen_staff',
    //   icon: 'station.wait',
    //   renderInList: false,
    //   notifyLabel: true,
    //   label: _this.$t('KITCHEN_STAFF.D'),
    //   value: {
    //     option: 2,
    //     options: [{
    //       label: 'None'
    //     }, {
    //       label: '1'
    //     }, {
    //       label: '2'
    //     }, {
    //       label: '3'
    //     }, {
    //       label: '4'
    //     }]
    //   }
    // }, {
    //   // services > barista_staff
    //   types: ['cafe', 'food', 'restaurant'],
    //   id: 'barista_staff',
    //   icon: 'station.wait',
    //   renderInList: false,
    //   notifyLabel: true,
    //   label: _this.$t('BARISTA_STAFF.D'),
    //   value: {
    //     option: 2,
    //     options: [{
    //       label: 'None'
    //     }, {
    //       label: '1'
    //     }, {
    //       label: '2'
    //     }, {
    //       label: '3'
    //     }, {
    //       label: '4'
    //     }]
    //   }
    // }, {
    //   // services > kitchen_wait_time
    //   types: ['cafe', 'food', 'restaurant'],
    //   id: 'kitchen_wait_time',
    //   icon: 'station.wait',
    //   renderInList: false,
    //   notifyLabel: true,
    //   label: _this.$t('KITCHEN_WAIT_TIME.D'),
    //   value: {
    //     option: 0,
    //     options: [{
    //       label: _this.$tc('MINUTES_VAL', 0, { count: 0 }),
    //       label_sh: _this.$tc('MINUTES_VAL_SH', 0, { count: 0 })
    //     }, {
    //       label: _this.$tc('MINUTES_VAL', 5, { count: 5 }),
    //       label_sh: _this.$tc('MINUTES_VAL_SH', 5, { count: 5 })
    //     }, {
    //       label: _this.$tc('MINUTES_VAL', 10, { count: 10 }),
    //       label_sh: _this.$tc('MINUTES_VAL_SH', 10, { count: 10 })
    //     }, {
    //       label: _this.$tc('MINUTES_VAL', 15, { count: 15 }),
    //       label_sh: _this.$tc('MINUTES_VAL_SH', 15, { count: 15 })
    //     }, {
    //       label: _this.$tc('MINUTES_VAL', 25, { count: 25 }),
    //       label_sh: _this.$tc('MINUTES_VAL_SH', 25, { count: 25 })
    //     }, {
    //       label: _this.$tc('MINUTES_ATLEAST_VAL', 30, { count: 30 }),
    //       label_sh: _this.$tc('MINUTES_ATLEAST_VAL_SH', 30, { count: 30 })
    //     }]
    //   }
    // }, {
    //   // services > barista_wait_time
    //   types: ['cafe', 'food', 'restaurant'],
    //   id: 'barista_wait_time',
    //   renderInList: false,
    //   notifyLabel: true,
    //   label: _this.$t('BARISTA_WAIT_TIME.D'),
    //   icon: 'station.wait',
    //   value: {
    //     option: 0,
    //     options: [{
    //       label: _this.$tc('MINUTES_VAL', 0, { count: 0 }),
    //       label_sh: _this.$tc('MINUTES_VAL_SH', 0, { count: 0 })
    //     }, {
    //       label: _this.$tc('MINUTES_VAL', 5, { count: 5 }),
    //       label_sh: _this.$tc('MINUTES_VAL_SH', 5, { count: 5 })
    //     }, {
    //       label: _this.$tc('MINUTES_VAL', 10, { count: 10 }),
    //       label_sh: _this.$tc('MINUTES_VAL_SH', 10, { count: 10 })
    //     }, {
    //       label: _this.$tc('MINUTES_VAL', 15, { count: 15 }),
    //       label_sh: _this.$tc('MINUTES_VAL_SH', 15, { count: 15 })
    //     }, {
    //       label: _this.$tc('MINUTES_VAL', 25, { count: 25 }),
    //       label_sh: _this.$tc('MINUTES_VAL_SH', 25, { count: 25 })
    //     }, {
    //       label: _this.$tc('MINUTES_ATLEAST_VAL', 30, { count: 30 }),
    //       label_sh: _this.$tc('MINUTES_ATLEAST_VAL_SH', 30, { count: 30 })
    //     }]
    //   }
    // // }, {
    // //   id: 'availability',
    // //   icon: 'text.badge.checkmark',
    // //   value: {
    // //     option: 0,
    // //     options: [{
    // //       label: _this.$t('AVAILABILITY.FULL')
    // //     }, {
    // //       label: _this.$t('AVAILABILITY.PARTIAL')
    // //     }]
    // //   }
    // // }, {
    // //   id: 'offers',
    // //   icon: 'gift.fill',
    // //   valueType: Boolean
    // }, {
    //   id: 'allowed_capacity',
    //   notifyLabel: true,
    //   icon: 'person.crop.circle.fill.badge.exclam',
    //   value: {
    //     option: 5,
    //     options: [{
    //       label: '1'
    //     }, {
    //       label: '2'
    //     }, {
    //       label: '3'
    //     }, {
    //       label: '4'
    //     }, {
    //       label: '5'
    //     }, {
    //       label: '6-10'
    //     }, {
    //       label: '10-50'
    //     }, {
    //       label: '50-100'
    //     }, {
    //       label: '100+'
    //     }]
    //   }
    }, {
      // loyalty card
      id: 'loyalty_card',
      icon: 'rectangle.stack.fill',
      notifyLabel: true,
      // label: _this.$t('LOYALTY_CARD.D'),
      delegatedCard: true,
      // paidFeature: 'loyalty_card',
      value: {
        option: 0,
        options: [{
          label: 'None'
        }, {
          label: '10th item for free.'
        }]
      }
    }]
  }, {
    // personnel
    id: 'staff',
    datafields: [{
      id: 'person',
      icon: 'person',
      description: 'Dr. Haitham Al-Beik',
      valueType: Boolean
    }, {
      id: 'team',
      icon: 'team',
      valueType: Boolean
    }]
  }, {
    // health and safety
    id: 'safety',
    datafields: [{
    //   // safety > masks
    //   id: 'masks',
    //   icon: 'mask',
    //   valueType: Boolean
    // }, {
    //   // safety > sanatizers
    //   id: 'hand_sanatizers',
    //   icon: 'hand',
    //   valueType: Boolean
    // }, {
      // safety > accessibility
      id: 'accessibility',
      icon: 'handicap',
      valueType: Boolean
    }]
  }, {
    // homes
    id: 'homes',
    types: ['real_estate_agency'],
    datafields: [{
      // homes > amenities
      id: 'amenities',
      icon: 'printer.fill',
      valueType: Boolean
    }, {
      // homes > available_properties
      id: 'available_properties',
      icon: 'bed.double.fill',
      valueType: Boolean
    }]
  }, {
    // department_store
    id: 'department_store',
    types: ['department_store', 'supermarket', 'grocery_or_supermarket'],
    datafields: [{
      // department_store > sanatizers
      id: 'sanatizers',
      icon: 'hand',
      valueType: Boolean
    }]
  }, {
    // allergens
    id: 'allergens',
    types: ['cafe', 'restaurant', 'food'],
    datafields: [{
      // allergens > food_vegan
      id: 'food_vegan',
      icon: 'food_vegan',
      valueType: Boolean
    }, {
      // allergens > food_gluten
      id: 'food_gluten',
      icon: 'food_gluten2',
      valueType: Boolean
    }, {
      // allergens > food_dairy
      id: 'food_dairy',
      icon: 'food_dairy',
      valueType: Boolean
    }]
  }, {
    // coffee
    id: 'coffee',
    types: ['cafe', 'restaurant', 'food'],
    datafields: [{
      id: 'espresso',
      icon: 'coffee',
      valueType: Boolean
    }, {
      id: 'man_aeropress',
      icon: 'coffee',
      valueType: Boolean
    }, {
      id: 'man_v60',
      icon: 'coffee',
      valueType: Boolean
    }, {
      id: 'man_chemex',
      icon: 'coffee',
      valueType: Boolean
    }]
  }, {
    // foods
    id: 'foods',
    types: ['cafe', 'restaurant', 'food'],
    datafields: [{
      // foods > inventory
      id: 'inventory',
      icon: 'gauge',
      value: {
        option: 0,
        options: [{
          label: _this.$t('INVENTORY.FULL')
        }, {
          label: _this.$t('INVENTORY.PARTIAL')
        }, {
          label: _this.$t('INVENTORY.LOW')
        }]
      }
    }, {
      id: 'burgers',
      icon: 'burger',
      valueType: Boolean
    }, {
      id: 'subs',
      icon: 'sub',
      valueType: Boolean
    }, {
      id: 'wraps',
      icon: 'wrap',
      valueType: Boolean
    }, {
      id: 'bowls',
      icon: 'bowl',
      valueType: Boolean
    }, {
      id: 'salad',
      icon: 'salad',
      valueType: Boolean
    }, {
      id: 'smoothies',
      icon: 'smoothie',
      valueType: Boolean
    }, {
      id: 'icecream',
      icon: 'icecream',
      valueType: Boolean
    }, {
      id: 'soups',
      icon: 'soup',
      valueType: Boolean
    }, {
      id: 'coffee',
      icon: 'coffee',
      valueType: Boolean
    }, {
      id: 'tea',
      icon: 'tea',
      valueType: Boolean
    }, {
      id: 'wine',
      icon: 'wine',
      valueType: Boolean
    }, {
      id: 'beer',
      icon: 'beer',
      valueType: Boolean
    }, {
      id: 'avocados',
      icon: 'avocados',
      valueType: Boolean
    }, {
      id: 'eggs',
      icon: 'eggs',
      valueType: Boolean
    }]
  }, {
    // auto
    id: 'auto',
    types: ['car_repair'],
    datafields: [{
      // auto > bays
      id: 'bays',
      icon: 'hammer.fill',
      value: {
        option: 0,
        options: [{
          label: _this.$t('ALL')
        }, {
          label: _this.$t('NONE')
        }, {
          label: _this.$tc('BAYS.COUNT', 1, { count: 1 })
        }, {
          label: _this.$tc('BAYS.COUNT', 2, { count: 2 })
        }, {
          label: _this.$tc('BAYS.COUNT', 3, { count: 3 })
        }, {
          label: _this.$tc('BAYS.COUNT', 4, { count: 4 })
        }, {
          label: _this.$tc('BAYS.COUNT', 5, { count: 5 })
        }, {
          label: _this.$tc('BAYS.COUNT', 6, { count: 6 })
        }, {
          label: _this.$tc('BAYS.COUNT', 7, { count: 7 })
        }, {
          label: _this.$tc('BAYS.COUNT', 8, { count: 8 })
        }, {
          label: _this.$tc('BAYS.COUNT', 9, { count: 9 })
        }, {
          label: _this.$tc('BAYS.COUNT', 10, { count: 10 })
        }]
      }
    }]
  }, {
    // animals
    id: 'animals',
    types: ['veterinary_care'],
    datafields: [{
      // animals > telehealth
      id: 'telehealth',
      icon: 'viewfinder',
      valueType: Boolean
    }]
  }, {
    // accepts
    id: 'accepts',
    datafields: [{
      // accepts > cash
      id: 'cash',
      icon: 'centsign.circle.fill',
      valueType: Boolean
    }, {
      // accepts > creditcard
      id: 'creditcard',
      icon: 'creditcard.fill',
      valueType: Boolean
    }, {
      // accepts > apple_pay
      id: 'applepay',
      icon: 'apple',
      valueType: Boolean
    }, {
      // accepts > crypto
      id: 'crypto',
      icon: 'bitcoinsign.circle.fill',
      valueType: Boolean
    }]
  }, {
    // orderby
    id: 'orderby',
    actions: {
      add_ignore: (datagroup) => {
        console.log(datagroup)
      }
    },
    datafields: [{
      // order-by > phone
      id: 'phone',
      icon: 'smartphone',
      type: 'phone',
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            text: _this.cleanURL(_this.product.data.business.phone)
          }
        }
      }
    }, {
      // order-by > website
      id: 'website',
      icon: 'link',
      type: 'link',
      valueType: Boolean,
      indicates: {
        requiresValue: true,
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: _this.product.data.business.website,
            text: _this.cleanURL(_this.product.data.business.website)
          }
        }
      }
    }, {
      // orderby > drivethru
      id: 'drivethru_ordering',
      icon: 'car.fill',
      notTypes: ['electronics_store'],
      valueType: Boolean
    }, {
      // order-by > doordash
      id: 'doordash',
      icon: 'link',
      type: 'link',
      types: ['restaurant', 'food'],
      archive: false,
      brand: true,
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: 'https://www.doordash.com',
            text: 'doordash.com'
          }
        }
      }
    }, {
      // order-by > grubhub
      id: 'grubhub',
      icon: 'link',
      type: 'link',
      types: ['restaurant', 'food'],
      archive: false,
      brand: true,
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: 'https://www.grubhub.com',
            text: 'grubhub.com'
          }
        }
      }
    }, {
      // order-by > ubereats
      id: 'ubereats',
      icon: 'link',
      type: 'link',
      types: ['restaurant', 'food'],
      archive: false,
      brand: true,
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: 'https://www.ubereats.com',
            text: 'ubereats.com'
          }
        }
      }
    }, {
      // order-by > postmates
      id: 'postmates',
      icon: 'link',
      type: 'link',
      types: ['restaurant', 'food'],
      archive: false,
      brand: true,
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: 'https://www.postmates.com',
            text: 'postmates.com'
          }
        }
      }
    }]
  }, {
    // pickup
    id: 'pickup',
    datafields: [{
      id: 'parking',
      icon: 'p.circle.fill',
      valueType: Boolean
    }, {
      id: 'drivethru',
      icon: 'car.fill',
      valueType: Boolean
    }, {
      id: 'walkins',
      icon: 'bubble.left.and.bubble.right.fill',
      valueType: Boolean
    }, {
      id: 'curbside',
      icon: 'bag.fill',
      valueType: Boolean
    }]
  }, {
    // business
    id: 'business',
    renderInList: false,
    actions: {
      remove: (datagroup) => {
        _this.remove()
      }
    },
    datafields: [{
      id: 'description',
      icon: 'info',
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: _this.product.data.business.metas ? _this.product.data.business.metas.description : '',
            unformat: true
          }
        }
      }
    }, {
      id: 'cta',
      icon: 'cta',
      label: _this.$t('CTA.D'),
      value: {
        option: 0,
        options: [{
          label: '--',
          sublabel: 'Do not display.'
        }, {
          label: 'share channel'
        }, {
          label: 'call business',
          dependsOn: 'phone'
        }, {
          label: 'order online',
          dependsOn: 'website'
        }, {
          label: 'order using doordash',
          dependsOn: 'doordash'
        }, {
          label: 'order using grubhub',
          dependsOn: 'grubhub'
        }, {
          label: 'order using ubereats',
          dependsOn: 'ubereats'
        }, {
          label: 'order using postmates',
          dependsOn: 'postmates'
        }]
      }
    }]
  }],
  venues: {
    list: {
      EXPOCITYDUBAI: {
        id: 'VID:EXPOCITYDUBAI',
        name: 'EXPO CITY DUBAI',
        logo: 'https://www.expocitydubai.com/_nuxt/img/logo-flower@2x.82734e6.png',
        // logo: 'https://www.expocitydubai.com/_nuxt/img/flower-white@2x.f642a17.webp',
        color: '#ffcc02',
        url: 'https://www.expocitydubai.com',
        businesses: [
          'ChIJu2E3I9lzXz4R3cOqDsMcTA4',
          'ChIJB34NwixzXz4RrCjpCBo-NuQ',
          'ChIJD_iOXqZzXz4R3UO3qBSAJWI',
          'ChIJmdDSCT1zXz4RY9HwBfGAUXA',
          'ChIJbU1_eHpzXz4RqjGwsY2krhA',
          'ChIJd2drMENzXz4R-I-lmLryRmw'
        ],
        qrcode: {
          body: 'circle',
          eye: 'frame1',
          eyeBall: 'ball1',
          bodyColor: '#000000',
          bgColor: '#ffffff',
          logo: 'https://res.cloudinary.com/letsbutterfly/image/upload/v1690013960/wings-app/assets/EXPO_CITY_DUBAI_LOGO.png',
          logoMode: 'clean',
          erf1: ['fh'],
          erf3: ['fh', 'fv'],
          // eye1Color: '#000000',
          // eye2Color: '#000000',
          // eye3Color: '#000000',
          // eyeBall1Color: '#AC8200',
          // eyeBall2Color: '#AC8200',
          // eyeBall3Color: '#AC8200',
          eye1Color: '#AC8200',
          eye2Color: '#AC8200',
          eye3Color: '#AC8200',
          eyeBall1Color: '#000000',
          eyeBall2Color: '#000000',
          eyeBall3Color: '#000000',
          brf1: ['fh'],
          brf3: ['fh', 'fv']
        }
      }
    },
    relations: {
      'ChIJu2E3I9lzXz4R3cOqDsMcTA4': 'EXPOCITYDUBAI',
      'ChIJB34NwixzXz4RrCjpCBo-NuQ': 'EXPOCITYDUBAI',
      'ChIJD_iOXqZzXz4R3UO3qBSAJWI': 'EXPOCITYDUBAI',
      'ChIJmdDSCT1zXz4RY9HwBfGAUXA': 'EXPOCITYDUBAI',
      'ChIJbU1_eHpzXz4RqjGwsY2krhA': 'EXPOCITYDUBAI',
      'ChIJd2drMENzXz4R-I-lmLryRmw': 'EXPOCITYDUBAI'
    }
  },
  cost: {
    default: {
      currencyIndex: 0,
      format: '0,0.00'
    },
    currency: {
      list: [
        { label: 'AED', name: 'United Arab Emirates dirham' },
        { label: 'AFN', name: 'Afghan afghani' },
        { label: 'ALL', name: 'Albanian lek' },
        { label: 'AMD', name: 'Armenian dram' },
        { label: 'ANG', name: 'Netherlands Antillean guilder' },
        { label: 'AOA', name: 'Angolan kwanza' },
        { label: 'ARS', name: 'Argentine peso' },
        { label: 'AUD', name: 'Australian dollar' },
        { label: 'AWG', name: 'Aruban florin' },
        { label: 'AZN', name: 'Azerbaijani manat' },
        { label: 'BAM', name: 'Bosnia and Herzegovina convertible mark' },
        { label: 'BBD', name: 'Barbadian dollar' },
        { label: 'BDT', name: 'Bangladeshi taka' },
        { label: 'BGN', name: 'Bulgarian lev' },
        { label: 'BHD', name: 'Bahraini dinar' },
        { label: 'BIF', name: 'Burundian franc' },
        { label: 'BMD', name: 'Bermudian dollar' },
        { label: 'BND', name: 'Brunei dollar' },
        { label: 'BOB', name: 'Bolivian boliviano' },
        { label: 'BRL', name: 'Brazilian real' },
        { label: 'BSD', name: 'Bahamian dollar' },
        { label: 'BTN', name: 'Bhutanese ngultrum' },
        { label: 'BWP', name: 'Botswana pula' },
        { label: 'BYN', name: 'Belarusian ruble' },
        { label: 'BZD', name: 'Belize dollar' },
        { label: 'CAD', name: 'Canadian dollar' },
        { label: 'CDF', name: 'Congolese franc' },
        { label: 'CHF', name: 'Swiss franc' },
        { label: 'CLP', name: 'Chilean peso' },
        { label: 'CNY', name: 'Chinese yuan' },
        { label: 'COP', name: 'Colombian peso' },
        { label: 'CRC', name: 'Costa Rican colón' },
        { label: 'CUC', name: 'Cuban convertible peso' },
        { label: 'CUP', name: 'Cuban peso' },
        { label: 'CVE', name: 'Cape Verdean escudo' },
        { label: 'CZK', name: 'Czech koruna' },
        { label: 'DJF', name: 'Djiboutian franc' },
        { label: 'DKK', name: 'Danish krone' },
        { label: 'DOP', name: 'Dominican peso' },
        { label: 'DZD', name: 'Algerian dinar' },
        { label: 'EGP', name: 'Egyptian pound' },
        { label: 'ERN', name: 'Eritrean nakfa' },
        { label: 'ETB', name: 'Ethiopian birr' },
        { label: 'EUR', name: 'Euro' },
        { label: 'FJD', name: 'Fijian dollar' },
        { label: 'FKP', name: 'Falkland Islands pound' },
        { label: 'GBP', name: 'Pound sterling' },
        { label: 'GEL', name: 'Georgian lari' },
        { label: 'GGP', name: 'Guernsey pound' },
        { label: 'GHS', name: 'Ghanaian cedi' },
        { label: 'GIP', name: 'Gibraltar pound' },
        { label: 'GMD', name: 'Gambian dalasi' },
        { label: 'GNF', name: 'Guinean franc' },
        { label: 'GTQ', name: 'Guatemalan quetzal' },
        { label: 'GYD', name: 'Guyanese dollar' },
        { label: 'HKD', name: 'Hong Kong dollar' },
        { label: 'HNL', name: 'Honduran lempira' },
        { label: 'HRK', name: 'Croatian kuna' },
        { label: 'HTG', name: 'Haitian gourde' },
        { label: 'HUF', name: 'Hungarian forint' },
        { label: 'IDR', name: 'Indonesian rupiah' },
        { label: 'ILS', name: 'Israeli new shekel' },
        { label: 'IMP', name: 'Manx pound' },
        { label: 'INR', name: 'Indian rupee' },
        { label: 'IQD', name: 'Iraqi dinar' },
        { label: 'IRR', name: 'Iranian rial' },
        { label: 'ISK', name: 'Icelandic króna' },
        { label: 'JEP', name: 'Jersey pound' },
        { label: 'JMD', name: 'Jamaican dollar' },
        { label: 'JOD', name: 'Jordanian dinar' },
        { label: 'JPY', name: 'Japanese yen' },
        { label: 'KES', name: 'Kenyan shilling' },
        { label: 'KGS', name: 'Kyrgyzstani som' },
        { label: 'KHR', name: 'Cambodian riel' },
        { label: 'KID', name: 'Kiribati dollar' },
        { label: 'KMF', name: 'Comorian franc' },
        { label: 'KPW', name: 'North Korean won' },
        { label: 'KRW', name: 'South Korean won' },
        { label: 'KWD', name: 'Kuwaiti dinar' },
        { label: 'KYD', name: 'Cayman Islands dollar' },
        { label: 'KZT', name: 'Kazakhstani tenge' },
        { label: 'LAK', name: 'Lao kip' },
        { label: 'LBP', name: 'Lebanese pound' },
        { label: 'LKR', name: 'Sri Lankan rupee' },
        { label: 'LRD', name: 'Liberian dollar' },
        { label: 'LSL', name: 'Lesotho loti' },
        { label: 'LYD', name: 'Libyan dinar' },
        { label: 'MAD', name: 'Moroccan dirham' },
        { label: 'MDL', name: 'Moldovan leu' },
        { label: 'MGA', name: 'Malagasy ariary' },
        { label: 'MKD', name: 'Macedonian denar' },
        { label: 'MMK', name: 'Burmese kyat' },
        { label: 'MNT', name: 'Mongolian tögrög' },
        { label: 'MOP', name: 'Macanese pataca' },
        { label: 'MRU', name: 'Mauritanian ouguiya' },
        { label: 'MUR', name: 'Mauritian rupee' },
        { label: 'MVR', name: 'Maldivian rufiyaa' },
        { label: 'MWK', name: 'Malawian kwacha' },
        { label: 'MXN', name: 'Mexican peso' },
        { label: 'MYR', name: 'Malaysian ringgit' },
        { label: 'MZN', name: 'Mozambican metical' },
        { label: 'NAD', name: 'Namibian dollar' },
        { label: 'NGN', name: 'Nigerian naira' },
        { label: 'NIO', name: 'Nicaraguan córdoba' },
        { label: 'NOK', name: 'Norwegian krone' },
        { label: 'NPR', name: 'Nepalese rupee' },
        { label: 'NZD', name: 'New Zealand dollar' },
        { label: 'OMR', name: 'Omani rial' },
        { label: 'PAB', name: 'Panamanian balboa' },
        { label: 'PEN', name: 'Peruvian sol' },
        { label: 'PGK', name: 'Papua New Guinean kina' },
        { label: 'PHP', name: 'Philippine peso' },
        { label: 'PKR', name: 'Pakistani rupee' },
        { label: 'PLN', name: 'Polish złoty' },
        { label: 'PRB', name: 'Transnistrian ruble' },
        { label: 'PYG', name: 'Paraguayan guaraní' },
        { label: 'QAR', name: 'Qatari riyal' },
        { label: 'RON', name: 'Romanian leu' },
        { label: 'RSD', name: 'Serbian dinar' },
        { label: 'RUB', name: 'Russian ruble' },
        { label: 'RWF', name: 'Rwandan franc' },
        { label: 'SAR', name: 'Saudi riyal' },
        { label: 'SEK', name: 'Swedish krona' },
        { label: 'SGD', name: 'Singapore dollar' },
        { label: 'SHP', name: 'Saint Helena pound' },
        { label: 'SLL', name: 'Sierra Leonean leone' },
        { label: 'SLS', name: 'Somaliland shilling' },
        { label: 'SOS', name: 'Somali shilling' },
        { label: 'SRD', name: 'Surinamese dollar' },
        { label: 'SSP', name: 'South Sudanese pound' },
        { label: 'STN', name: 'São Tomé and Príncipe dobra' },
        { label: 'SYP', name: 'Syrian pound' },
        { label: 'SZL', name: 'Swazi lilangeni' },
        { label: 'THB', name: 'Thai baht' },
        { label: 'TJS', name: 'Tajikistani somoni' },
        { label: 'TMT', name: 'Turkmenistan manat' },
        { label: 'TND', name: 'Tunisian dinar' },
        { label: 'TOP', name: 'Tongan pa\'anga' },
        { label: 'TRY', name: 'Turkish lira' },
        { label: 'TTD', name: 'Trinidad and Tobago dollar' },
        { label: 'TVD', name: 'Tuvaluan dollar' },
        { label: 'TWD', name: 'New Taiwan dollar' },
        { label: 'TZS', name: 'Tanzanian shilling' },
        { label: 'UAH', name: 'Ukrainian hryvnia' },
        { label: 'UGX', name: 'Ugandan shilling' },
        { label: 'USD', name: 'United States dollar' },
        { label: 'UYU', name: 'Uruguayan peso' },
        { label: 'UZS', name: 'Uzbekistani so\'m' },
        { label: 'VES', name: 'Venezuelan bolívar soberano' },
        { label: 'VND', name: 'Vietnamese đồng' },
        { label: 'VUV', name: 'Vanuatu vatu' },
        { label: 'WST', name: 'Samoan tālā' },
        { label: 'XAF', name: 'Central African CFA franc' },
        { label: 'XCD', name: 'Eastern Caribbean dollar' },
        { label: 'XOF', name: 'West African CFA franc' },
        { label: 'XPF', name: 'CFP franc' },
        { label: 'ZAR', name: 'South African rand' },
        { label: 'ZMW', name: 'Zambian kwacha' },
        { label: 'ZWB', name: 'Zimbabwean bond' }
      ]
    }
  },
  services: {
    defaults: {
      descriptors: {
        availability: {
          null: {
            label: 'Not Set',
            sublabel: 'New Service.'
          },
          '0': {
            label: 'Fully Available',
            sublabel: 'Completely ready for use.'
          },
          '1': {
            label: 'Partially Available',
            sublabel: 'Limited use only.'
          },
          '2': {
            label: 'Not Available',
            sublabel: 'Currently not available.'
          },
          '3': {
            label: 'Maintenance',
            sublabel: 'Undergoing repairs or upgrades.'
          }
        }
      }
    },
    categories: {
      list: [{
        label: 'Baked Goods',
        value: 'baking'
      }, {
        label: 'Fitness',
        value: 'fitness'
      }, {
        label: 'Food & Beverage',
        value: 'fb'
      }, {
        label: 'Farm',
        value: 'farm'
      }, {
        label: 'Staff',
        value: 'people'
      }, {
        label: 'Laundry',
        value: 'laundry'
      }, {
        label: 'Mobility',
        value: 'mobility'
      }, {
        label: 'Sanitation',
        value: 'sanitation'
      }, {
        label: 'Seating',
        value: 'seating'
      }, {
        label: 'Security',
        value: 'security'
      }, {
        label: 'Technology',
        value: 'technology'
      }, {
        label: 'Information',
        value: 'info'
      }]
    },
    list: {
      sanitation: {
        bathroom: {
          id: 'bathroom',
          name: 'Bathroom'
        },
        shower: {
          id: 'shower',
          name: 'Shower'
        }
      },
      people: {
        staff_onpremise: {
          id: 'staff_onpremise',
          name: 'Staff on Premise'
        },
        person_onpremise: {
          id: 'person_onpremise',
          name: 'Person on Premise'
        },
      },
      laundry: {
        washer: {
          id: 'washer',
          name: 'Washer'
        },
        dryer: {
          id: 'dryer',
          name: 'Dryer'
        }
      },
      technology: {
        tablet: {
          id: 'tablet',
          name: 'Tablet',
          description: 'Tablet'
        },
        tv: {
          id: 'tv',
          name: 'TV',
          description: 'TV'
        },
        printer: {
          id: 'printer',
          name: 'Printer',
          description: 'Printer'
        },
        wifi: {
          id: 'wifi',
          name: 'Wi-Fi',
          description: 'Wi-Fi'
        }
      },
      baking: {
        oven: {
          id: 'oven',
          name: 'Oven'
        },
        cookies: {
          id: 'cookies',
          name: 'Cookies'
        },
        croissant: {
          id: 'croissant',
          name: 'Croissant'
        },
        bread: {
          id: 'bread',
          name: 'Bread'
        },
        muffin: {
          id: 'muffin',
          name: 'Muffin'
        },
        cupcake: {
          id: 'cupcake',
          name: 'Cupcake'
        },
        cake_loaf: {
          id: 'cake_loaf',
          name: 'Loaf Cake'
        },
        donut: {
          id: 'donut',
          name: 'Donut'
        },
        brownie: {
          id: 'brownie',
          name: 'Brownie'
        },
        pastry_puff: {
          id: 'pastry_puff',
          name: 'Puff Pastry'
        },
        roll: {
          id: 'roll',
          name: 'Roll'
        }
      },
      fb: {
        coffee: {
          id: 'coffee',
          name: 'Coffee'
        },
        espresso: {
          id: 'espresso',
          name: 'Espresso'
        },
        coffee_beans: {
          id: 'coffee_beans',
          name: 'Coffee Beans'
        },
        cezve: {
          id: 'cezve',
          name: 'Cezve'
        },
        coffee_pot: {
          id: 'coffee_pot',
          name: 'Coffee Pot'
        },
        moka_pot: {
          id: 'moka_pot',
          name: 'Moka Pot'
        },
        milk_bottle: {
          id: 'milk_bottle',
          name: 'Milk Bottle'
        },
        coffee_grinder: {
          id: 'coffee_grinder',
          name: 'Coffee Grinder'
        },
        coffee_maker: {
          id: 'coffee_maker',
          name: 'Coffee Machine'
        },
        espresso_machine: {
          id: 'espresso_machine',
          name: 'Espresso Machine'
        },
        aeropress: {
          id: 'aeropress',
          name: 'Aeropress'
        },
        french_press: {
          id: 'french_press',
          name: 'French Press'
        },
        chemex: {
          id: 'chemex',
          name: 'Chemex'
        },
        v60: {
          id: 'v60',
          name: 'V60'
        },
        kinto: {
          id: 'kinto',
          name: 'Kinto'
        },
        drink_iced: {
          id: 'drink_iced',
          name: 'Iced Drink'
        },
        drink_hot: {
          id: 'drink_hot',
          name: 'Hot Drink'
        },
        tea_hot: {
          id: 'tea_hot',
          name: 'Hot Tea'
        },
        juice_bottle: {
          id: 'juice_bottle',
          name: 'Juice Bottle'
        },
        drink_carbonated: {
          id: 'drink_carbonated',
          name: 'Carbonated Drink'
        },
        water_bottle: {
          id: 'water_bottle',
          name: 'Water Bottle'
        },
        dispenser_drink: {
          id: 'dispenser_drink',
          name: 'Drink Dispenser'
        },
        draft: {
          id: 'draft',
          name: 'Draft Drink'
        },
        sandwich_sub: {
          id: 'sandwich_sub',
          name: 'Sandwich Sub'
        },
        sandwich_tramezzino: {
          id: 'sandwich_tramezzino',
          name: 'Sandwich Tramezzino'
        },
        soup: {
          id: 'soup',
          name: 'Soup'
        },
        salad: {
          id: 'salad',
          name: 'Salad'
        }
      },
      farm: {
        lettuce: {
          id: 'lettuce',
          name: 'Lettuce'
        },
        spinach: {
          id: 'spinach',
          name: 'Spinach'
        },
        arugula: {
          id: 'arugula',
          name: 'Arugula'
        },
        tomato: {
          id: 'tomato',
          name: 'Tomato'
        },
        cucumber: {
          id: 'cucumber',
          name: 'Cucumber'
        },
        carrot: {
          id: 'carrot',
          name: 'Carrot'
        },
        beans: {
          id: 'beans',
          name: 'Beans'
        },
        fennel: {
          id: 'fennel',
          name: 'Fennel'
        },
        cabbage: {
          id: 'cabbage',
          name: 'Cabbage'
        },
        kale: {
          id: 'kale',
          name: 'Kale'
        },
        mint: {
          id: 'mint',
          name: 'Mint'
        },
        basil: {
          id: 'basil',
          name: 'Basil'
        }
      },
      security: {
        camera: {
          id: 'camera',
          name: 'Camera'
        }
      },
      mobility: {
        metro: {
          id: 'metro',
          name: 'Metro'
        },
        tram: {
          id: 'tram',
          name: 'Tram'
        },
        bus: {
          id: 'bus',
          name: 'Bus'
        },
        train: {
          id: 'train',
          name: 'Train'
        },
        buggy: {
          id: 'buggy',
          name: 'Buggy'
        },
        parking: {
          id: 'parking',
          name: 'Parking'
        }
      },
      fitness: {
        sauna: {
          id: 'sauna',
          name: 'Sauna'
        },
        cycle_standing_lever: {
          id: 'cycle_standing_lever',
          name: 'Standing Cycle',
          description: 'Standing with levers'
        },
        cycle_sitting_lever: {
          id: 'cycle_sitting_lever',
          name: 'Sitting Cycle',
          description: 'Sitting with handles'
        },
        treadmill: {
          id: 'treadmill',
          name: 'Treadmill'
        },
        treadmill_incline: {
          id: 'treadmill_incline',
          name: 'Treadmill',
          description: 'Incline'
        }
      },
      seating: {
        seating_indoor: {
          id: 'seating_indoor',
          name: 'Indoor Seating'
        },
        seating_outdoor: {
          id: 'seating_outdoor',
          name: 'Outdoor Seating'
        }
      },
      info: {
        wait_time: {
          id: 'wait_time',
          name: 'Wait Time',
          descriptors: {
            availability: {
              null: {
                label: 'Not Set',
                sublabel: 'New Service.'
              },
              '0': {
                label: 'None to Short Wait',
                sublabel: 'Immediate to 10 minutes.'
              },
              '1': {
                label: 'Short Wait',
                sublabel: 'Up to 20 minutes.'
              },
              '2': {
                label: 'Long Wait',
                sublabel: 'Near or at capacity.'
              },
              '3': {
                label: 'Evaluating',
                sublabel: 'Re-assessing capacity.'
              }
            }
          }
        }
      }
    }
  }
}
